import { Alpine as AlpineType } from 'alpinejs'

declare global {
  interface Window {
    Alpine: AlpineType;
  }
}

import "@hotwired/turbo-rails";
import "@rails/request.js";

import "~/assets/javascript/controllers";
import "~/components";

import "alpine-turbo-drive-adapter";
import Alpine from "alpinejs";
window.Alpine = Alpine;
Alpine.start();

import "~/config/cookie";
import "~/config/sentry";
import "~/config/mixpanel";
